<template>
  <div class="vx-col w-full">
  <div class="vx-card">
<div class="vx-card__collapsible-content vs-con-loading__container">
  <div class="vx-card__body">
<div class="vx-row -mx-2">
<div class="vx-col px-2 w-full mb-3">
<div data-v-197288ec="" class="vx-con--tabs monitoring-test-script" style="">
  <div data-v-197288ec="" class="vx-con--tabs-block">
 <div class="vx-tab-content" icon="icon-code" title="Test script" data-v-197288ec="" style="">
  <div id="editorcontainer" style="resize: vertical; overflow: auto; min-height: 500px; min-width:500px"></div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</template>

<script>
  //import MonacoEditor from 'vue-monaco'
  //import MonacoEditor from 'monaco-editor-vue';
  import * as monaco from  'monaco-editor';
  //import 'monaco-editor/esm/vs/language/json/monaco.contribution';
/*  import 'monaco-editor/esm/vs/language/typescript/monaco.contribution';
  import 'monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution';
  import 'monaco-editor/esm/vs/editor/editor.all';
*/
/* eslint-disable */


function ShowAutocompletion(obj) { 
    // Disable default autocompletion for javascript
    //monaco.languages.typescript.javascriptDefaults.setCompilerOptions({ noLib: true  });
  
    // Helper function to return the monaco completion item type of a thing
    function getType(thing, isMember) {
      isMember =  (isMember == undefined) ? (typeof isMember == "boolean") ? isMember : false : false; // Give isMember a default value of false
    
      switch ((typeof thing).toLowerCase()) { 
        case "object": 
          return monaco.languages.CompletionItemKind.Class;

        case "function": 
          return (isMember) ? monaco.languages.CompletionItemKind.Method : monaco.languages.CompletionItemKind.Function;

        default: 
          return (isMember) ? monaco.languages.CompletionItemKind.Property : monaco.languages.CompletionItemKind.Variable;
      }
    }
  
    // Register object that will return autocomplete items 
    monaco.languages.registerCompletionItemProvider('javascript', {
      // Run this function when the period or open parenthesis is typed (and anything after a space)
      triggerCharacters: ['.', '('],

      // Function to generate autocompletion results
      provideCompletionItems: function(model, position, token) {
        // Split everything the user has typed on the current line up at each space, and only look at the last word
        var last_chars = model.getValueInRange({startLineNumber: position.lineNumber, startColumn: 0, endLineNumber: position.lineNumber, endColumn: position.column});
        var words = last_chars.replace("\t", "").split(" "); 
        var active_typing = words[words.length - 1]; // What the user is currently typing (everything after the last space)
  
        // If the last character typed is a period then we need to look at member objects of the obj object 
        var is_member = active_typing.charAt(active_typing.length - 1) == ".";

        // Array of autocompletion results
        var result = [];
            
        // Used for generic handling between member and non-member objects
        var last_token = obj; 
        var prefix = ''; 
      
        if (is_member) { 
          // Is a member, get a list of all members, and the prefix
          var parents = active_typing.substring(0, active_typing.length - 1).split("."); 
          last_token = obj[parents[0]]; 
          prefix = parents[0]; 

          // Loop through all the parents the current one will have (to generate prefix)
          for (var i = 1; i < parents.length; i++) { 
            if (last_token.hasOwnProperty(parents[i])) { 
              prefix += '.' + parents[i]; 
              last_token = last_token[parents[i]];
            } else { 
              // Not valid
              return result;
            }
          }
      
          prefix += '.';
        }
      
        // Get all the child properties of the last token
        for (var prop in last_token) { 
          // Do not show properites that begin with "__"
          if (last_token.hasOwnProperty(prop) && !prop.startsWith("__")) { 
            // Get the detail type (try-catch) incase object does not have prototype 
            var details = ''; 
            try { 
              details = last_token[prop].__proto__.constructor.name; 
            } catch (e) { 
              details = typeof last_token[prop]; 
            }
            
            // Create completion object
            var to_push = {
              label: prefix + prop,
              kind: getType(last_token[prop], is_member), 
              detail: details,     
              insertText: prop
            };

            // Change insertText and documentation for functions
            if (to_push.detail.toLowerCase() == 'function') { 
              to_push.insertText += "(";
              to_push.documentation = (last_token[prop].toString()).split("{")[0]; // Show function prototype in the documentation popup
            }

            // Add to final results
            result.push(to_push);
          }
        }

        return {
            suggestions: result
        };
      }
  });
}
/*
ShowAutocompletion({ 
  Person: { 
    name: "",
    age: 0
  }
});
*/

export default {
  components: {
    //MonacoEditor: MonacoEditor
  },
  data() {
    return {
      updatedScript: '',
      model: this.$attrs.script || 'xxxx',
      options: {
        automaticLayout: true,
        language: "javascript",

        lineNumbers: "on",
        roundedSelection: false,
        scrollBeyondLastLine: false,
        readOnly: false,
        theme: "vs-dark",
      }
    }
  },
  mounted() {
// validation settings


/*
monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
  noSemanticValidation: true,
  noSyntaxValidation: false
});

// compiler options
monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
  target: monaco.languages.typescript.ScriptTarget.ES6,
  allowNonTsExtensions: true
});
*/
    window.editor = monaco.editor.create(document.getElementById('editorcontainer'), {
      value: 'hello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\nhello\n',
      language: 'javascript'
    });

    console.log('Set it up', window.editor );

    
  },
  methods: {
    onChange(newValue) {
      //console.log(newValue, this);
      //this.data.updatedScript = newValue;
    },
    editorDidMount(editor) {
      window.editor = editor;
      // Listen to `scroll` event
      editor.onDidScrollChange(e => {
        console.log(e)
      })
    }

  }
}

window.onresize = () => {
  //console.log('Window resize');
  //window.editor.layout({ height: 500 });
};



</script>